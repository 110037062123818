import { uniq } from 'midash'

export const labelKeys = {
  star: 'star',
  top: 'top',
  color: 'color',
  interview: 'interview',
  playground: 'playground',
  dev: 'dev',
  image: 'image',
  encode: 'encode',
  convert: 'convert',
  format: 'format',
  css: 'css',
  performance: 'performance',
  regexp: 'regexp',
  design: 'design',
  seo: 'seo',
  gis: 'gis'
}

type Tool = any

export function getToolsByLabel(devtools: Tool[], label: string) {
  return devtools.filter(tool => {
    return tool.labels?.includes(label)
  }) || []
}

export function getLabels (devtools: Tool[]) {
  return uniq(devtools.flatMap(tool => tool.labels))
}

export function getLabelByHref (devtools: Tool[], href: string) {
  return devtools.find(tool => {
    return tool.href === href
  })?.labels?.[0]
}