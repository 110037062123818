import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import Logo from '../../svg/logo'
import { cn } from '@/utils/cn'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '../ui/breadcrumb'
import { getLabelByHref } from '@/utils/label'
import { useDevtools } from '@/hooks/useDevtool'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

export default function Header({ title, className }: { title?: string, className?: string }) {
  const router = useRouter()
  const devtools = useDevtools()
  const path = router.asPath
  const label = getLabelByHref(devtools, path)
  const isHomePage = router.pathname === '/'
  const t = useTranslations('Layout.Header')
  const labelT = useTranslations('Layout.Labels')

  const handleLanguageChange = (value: string) => {
    router.push(router.pathname, router.asPath, { locale: value })
  }

  return (
    <header className={cn('font-serif flex justify-between items-center header py-4 sticky top-0 z-50 w-full border-border/40 backdrop-blur', className)}>
      <div className="text-3xl">
        <Link href="/" prefetch={false}>
          <Logo className="inline-block mr-2 text-orange-500 cursor-pointer w-9" />
          <span>
            {title ?? t('title')}
          </span>
        </Link>
      </div>
      <div className="flex items-center gap-4">
        {!isHomePage && (
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">{t('home')}</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              {label && (
                <>
                  <BreadcrumbItem>
                    <BreadcrumbLink href={`/for-${label}`}>
                      {labelT(label)}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator />
                </>
              )}
              <BreadcrumbItem>
                <BreadcrumbLink href={path}>{title}</BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        )}
        <a href="https://xunlianying.feishu.cn/share/base/form/shrcnJvE9nNqrFsl32PT01mvooh" className="no-underline text-sm" target="_blank" rel="noopener noreferrer">
          {t('feedback')}
        </a>
        <Select
          value={router.locale || router.defaultLocale}
          onValueChange={handleLanguageChange}
        >
          <SelectTrigger className="w-[100px] ml-4">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="en">English</SelectItem>
            <SelectItem value="zh">中文</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </header>
  )
}
