function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M331.75 225C360.05 225 386.65 236 406.65 256L426.05 275.4C441.85 268.5 456.85 258.9 469.85 245.9C506.95 208.8 519.55 156.6 507.75 109.2C505.55 100.2 494.25 97.1 487.65 103.7L413.25 178.1L345.35 166.8L334.05 98.9L408.45 24.5C415.05 17.9 411.85 6.60001 402.75 4.30001C355.35 -7.39999 303.15 5.20001 266.15 42.2C237.65 70.7 224.25 108.3 224.95 145.8L307.05 227.9C315.15 226 323.55 225 331.75 225ZM227.85 307L171.15 250.3L18.75 402.8C-6.25 427.8 -6.25 468.3 18.75 493.3C43.75 518.3 84.25 518.3 109.25 493.3L232.85 369.7C225.25 349.8 222.95 328.1 227.85 307ZM64.05 472C50.85 472 40.05 461.2 40.05 448C40.05 434.7 50.75 424 64.05 424C77.35 424 88.05 434.7 88.05 448C88.05 461.2 77.35 472 64.05 472Z" fill="#BBBBBB" />
      <path opacity="0.94" d="M502 399.7L384.9 282.6C361.8 259.5 327.3 255 299.5 268.7L42 19.5L10.5 53.5L265.6 302.6C252 330.4 256.4 364.9 279.5 388L396.6 505.1C411.2 519.7 434.8 519.7 449.3 505.1L502 452.4C516.5 437.8 516.5 414.2 502 399.7Z" fill="#FC923C" />
    </svg>
  )
}

export default SvgComponent
