import { useTranslations } from 'next-intl';
import { Home, Star, Palette, BookOpen, Code, Wrench, Image, Hash, FileType } from "lucide-react";

export type Tool = {
  name: string;
  href: string;
  description: string;
  image: string;
  thumbnail: string;
  labels: string[];
}

export const useDevtools = (): Tool[] => {
  const t = useTranslations('Devtools');

  return [
    {
      name: t('markdownCV.name'),
      href: "https://cv.devtool.tech/app",
      description: t('markdownCV.description'),
      image: "cv.svg",
      thumbnail: "cv.png",
      labels: ["interview"]
    },
    {
      name: t('markdownEditor.name'),
      href: "https://markdown.devtool.tech/app",
      description: t('markdownEditor.description'),
      image: "mdtu.svg",
      thumbnail: "mdtu.png",
      labels: ["playground"]
    },
    {
      name: t('npmOnline.name'),
      href: "https://npm.devtool.tech",
      description: t('npmOnline.description'),
      image: "npmdevtool.svg",
      thumbnail: "npmdevtool.png",
      labels: ["playground"]
    },
    {
      name: t('apifox.name'),
      href: "https://www.apifox.cn/?utm_source=shanyue-question",
      description: t('apifox.description'),
      image: "apifox.png",
      thumbnail: "apifox.png",
      labels: ["dev"]
    },
    {
      name: t('colorConvert.name'),
      href: "/color",
      description: t('colorConvert.description'),
      image: "logo.svg",
      thumbnail: "images/24-10-16/clipboard-7410.0bff76.webp",
      labels: ["color"]
    },
    {
      name: t('colorPalette.name'),
      href: "/palette",
      description: t('colorPalette.description'),
      image: "logo.svg",
      thumbnail: "palette.png",
      labels: ["color"]
    },
    {
      name: t('cssColorNames.name'),
      href: "/css-color-name",
      description: t('cssColorNames.description'),
      image: "logo.svg",
      thumbnail: "csscolor.png",
      labels: ["color"]
    },
    {
      name: t('contrastRatio.name'),
      href: "/contrast-ratio",
      description: t('contrastRatio.description'),
      image: "logo.svg",
      thumbnail: "images/24-10-16/clipboard-2432.3b8755.webp",
      labels: ["color"]
    },
    {
      name: t('colorLuminance.name'),
      href: "/color-lumi",
      description: t('colorLuminance.description'),
      image: "logo.svg",
      thumbnail: "lumi.png",
      labels: ["color"]
    },
    {
      name: t('colorGrayscale.name'),
      href: "/color-grayscale",
      description: t('colorGrayscale.description'),
      image: "logo.svg",
      thumbnail: "color-gray.png",
      labels: ["color"]
    },
    {
      name: t('imageGrayscale.name'),
      href: "/image-grayscale",
      description: t('imageGrayscale.description'),
      image: "logo.svg",
      thumbnail: "image-grayscale.png",
      labels: ["image"]
    },
    {
      name: t('tinyImage.name'),
      href: "/tiny-image",
      description: t('tinyImage.description'),
      image: "logo.svg",
      thumbnail: "images/24-10-16/clipboard-5192.d4318d.webp",
      labels: ["image"]
    },
    {
      name: t('imageGallery.name'),
      href: "/gallery",
      description: t('imageGallery.description'),
      image: "logo.svg",
      thumbnail: "images/24-10-16/clipboard-3754.4cf7fe.webp",
      labels: ["image"]
    },
    {
      name: t('symmetricEncryption.name'),
      href: "/encryption",
      image: "logo.svg",
      description: t('symmetricEncryption.description'),
      thumbnail: "encryption.png",
      labels: ["encode"]
    },
    {
      name: t('imagePlaceholder.name'),
      href: "/placeholder",
      image: "logo.svg",
      description: t('imagePlaceholder.description'),
      thumbnail: "images/24-10-16/clipboard-0895.d25b6d.webp",
      labels: ["image"]
    },
    {
      name: t('htmlToMarkdown.name'),
      href: "/html-md",
      description: t('htmlToMarkdown.description'),
      image: "logo.svg",
      thumbnail: "htmlmd.png",
      labels: ["playground"]
    },
    {
      name: t('dataUrl.name'),
      href: "/dataurl",
      description: t('dataUrl.description'),
      image: "logo.svg",
      thumbnail: "dataurl.png",
      labels: ["image"]
    },
    {
      name: t('feLogo.name'),
      href: "/fe-logo",
      description: t('feLogo.description'),
      image: "logo.svg",
      thumbnail: "fe-logo.png",
      labels: ["dev"]
    },
    {
      name: t('reactLive.name'),
      href: "/react",
      description: t('reactLive.description'),
      image: "logo.svg",
      thumbnail: "react500.png",
      labels: ["playground"]
    },
    {
      name: t('qrCode.name'),
      href: "/qrcode",
      description: t('qrCode.description'),
      image: "logo.svg",
      thumbnail: "qrcode.png",
      labels: ["image"]
    },
    {
      name: t('semver.name'),
      href: "/semver",
      description: t('semver.description'),
      image: "logo.svg",
      thumbnail: "semver.png",
      labels: ["dev"]
    },
    {
      name: t('treeStructure.name'),
      href: "/tree",
      description: t('treeStructure.description'),
      image: "logo.svg",
      thumbnail: "tree.png",
      labels: ["dev"]
    },
    {
      name: t('uaParser.name'),
      href: "/ua-parser",
      description: t('uaParser.description'),
      thumbnail: "ua.webp",
      image: "logo.svg",
      labels: ["dev"]
    },
    {
      name: t('exifViewer.name'),
      href: "/exif",
      description: t('exifViewer.description'),
      image: "logo.svg",
      thumbnail: "exif.png",
      labels: ["image"]
    },
    {
      name: t('ieee754.name'),
      href: "/double-type",
      image: "logo.svg",
      description: t('ieee754.description'),
      thumbnail: "images/24-10-13/clipboard-4215.a7ecb1.webp",
      labels: ["dev"]
    },
    {
      name: t('binaryConverter.name'),
      href: "/bin-convert",
      image: "logo.svg",
      description: t('binaryConverter.description'),
      thumbnail: "bin-convert.png",
      labels: ["convert"]
    },
    {
      name: t('base64.name'),
      href: "/base64",
      image: "logo.svg",
      description: t('base64.description'),
      thumbnail: "images/24-10-16/clipboard-2684.f3dea6.webp",
      labels: ["encode"]
    },
    {
      name: t('unicodeToCodePoint.name'),
      href: "/code-point",
      image: "logo.svg",
      description: t('unicodeToCodePoint.description'),
      thumbnail: "codepoint.png",
      labels: ["encode"]
    },
    {
      name: t('unicodeToUtf8.name'),
      href: "/utf8",
      image: "logo.svg",
      thumbnail: "images/24-10-16/clipboard-9015.e279b0.webp",
      description: t('unicodeToUtf8.description'),
      labels: ["encode"]
    },
    {
      name: t('unicodeToUtf16.name'),
      href: "/utf16",
      image: "logo.svg",
      thumbnail: "utf16.png",
      description: t('unicodeToUtf16.description'),
      labels: ["encode"]
    },
    {
      name: t('urlEncoding.name'),
      href: "/url-encode",
      image: "logo.svg",
      thumbnail: "url-encode.png",
      description: t('urlEncoding.description'),
      labels: ["encode"]
    },
    {
      name: t('htmlEntityEncoding.name'),
      href: "/entity",
      image: "logo.svg",
      description: t('htmlEntityEncoding.description'),
      thumbnail: "entity.png",
      labels: ["encode"]
    },
    {
      name: t('hashGenerator.name'),
      href: "/hash",
      image: "logo.svg",
      description: t('hashGenerator.description'),
      thumbnail: "hash.png",
      labels: ["encode"]
    },
    {
      name: t('uuidGenerator.name'),
      href: "/uuid",
      image: "logo.svg",
      thumbnail: "uuid.png",
      description: t('uuidGenerator.description'),
      labels: ["dev"]
    },
    {
      name: t('fileTypeDetector.name'),
      href: "/filetype",
      image: "logo.svg",
      thumbnail: "images/24-10-16/clipboard-4722.45bad5.webp",
      description: t('fileTypeDetector.description'),
      labels: ["dev"]
    },
    {
      name: t('asciiTable.name'),
      href: "/ascii",
      description: t('asciiTable.description'),
      thumbnail: "ascii.png",
      image: "logo.svg",
      labels: ["encode"]
    },
    {
      name: t('imageShare.name'),
      href: "/image-share",
      description: t('imageShare.description'),
      image: "logo.svg",
      thumbnail: "images/24-10-16/clipboard-0477.a2915f.webp",
      labels: ["image"]
    },
    {
      name: t('ipInfo.name'),
      href: "/ip",
      description: t('ipInfo.description'),
      image: "logo.svg",
      thumbnail: "ip.png",
      labels: ["dev"]
    }
  ];
};

export const useLabels = () => {
  const t = useTranslations('Layout');

  return [
    { key: "star", label: t('Labels.star'), icon: Star, href: "/for-star" },
    { key: "color", label: t('Labels.color'), icon: Palette, href: "/for-color" },
    { key: "interview", label: t('Labels.interview'), icon: BookOpen, href: "/for-interview" },
    { key: "playground", label: t('Labels.playground'), icon: Code, href: "/for-playground" },
    { key: "dev", label: t('Labels.dev'), icon: Wrench, href: "/for-dev" },
    { key: "image", label: t('Labels.image'), icon: Image, href: "/for-image" },
    { key: "encode", label: t('Labels.encode'), icon: Hash, href: "/for-encode" },
    { key: "convert", label: t('Labels.convert'), icon: FileType, href: "/for-convert" },
  ];
};